export const onRequest = (config) => {
  return config;
};

export const onRequestError = (error) => {
  return Promise.reject(error);
};

export const onResponse = (response) => {
  return response;
};

export const onResponseError = (error) => {
  return Promise.reject(error);
};
