export const GET_USERS_INFO = 'GET_USERS_INFO';
export const GET_USERS_INFO_SUCCESS = 'GET_USERS_INFO_SUCCESS';

export const getUsersInfoAC = (userIds) => ({
  type: GET_USERS_INFO,
  userIds,
});

export const getUsersInfoSuccessAC = (userIds, users) => ({
  type: GET_USERS_INFO_SUCCESS,
  userIds,
  users,
});
