import createReducer from '../createReducer';
import {GET_USERS_INFO, GET_USERS_INFO_SUCCESS} from '../../actions/common';

// Cache 60s
const CACHE_TIME = 60 * 1000;

const initialState = {};

const handlers = {
  [GET_USERS_INFO]: (state, action) => {
    const items = action.userIds.reduce((result, id) => ({
      ...result,
      [id]: {
        ...state[id],
        isFetching: true,
        id: id
      }
    }), {});
    if (!action.forceRefresh) {
      const now = new Date().getTime();
      Object.keys(items).forEach(key => {
        if (!items[key].isLoaded || !items[key].info) {
          return;
        }
        const diff = now - items[key].loadedAt;
        if (diff < CACHE_TIME) {
          delete items[key];
        }
      });
    }
    return {...state, ...items};
  },
  [GET_USERS_INFO_SUCCESS]: (state, action) => {
    const items = {};
    action.userIds.forEach(item => {
      items[item] = {
        isFetching: false,
        isLoaded: true,
        loadedAt: new Date().getTime(),
        info: null,
      }
    });
    action.users.forEach(item => {
      items[item.id] = {
        ...items[item.id],
        info: item
      };
    });
    return {...state, ...items};
  },
};

export default createReducer(initialState, handlers);
