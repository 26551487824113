import {all} from 'redux-saga/effects';
import CommonWatcher from './common';
import UserWatcher from './user';

export default function* rootSaga() {
  yield all([
    ...CommonWatcher,
    ...UserWatcher,
  ])
}
