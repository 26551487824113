export const GET_CURRENT_USER_AC = 'GET_CURRENT_USER_AC';
export const GET_CURRENT_USER_SUCCESS_AC = 'GET_CURRENT_USER_SUCCESS_AC';

export const getCurrentUserAC = (isCheckLogin = false) => ({
  type: GET_CURRENT_USER_AC,
  isCheckLogin,
});

export const getCurrentUserSuccessAC = (data) => ({
  type: GET_CURRENT_USER_SUCCESS_AC,
  data,
});
